<template>
  <Expand type="leave-a-review" transition="slide-left" @close="resetForm">
    <template v-slot:top>
      <h2>Leave A Review</h2>
    </template>
    <template v-slot:content>
      <div v-show="!isLoggedIn" class="link-button-wrapper">
        <p>You must be logged in to leave a review!</p>
        <Button @click="openHeaderMenuExpand('login')" text="Log In" />
        <Button @click="openHeaderMenuExpand('register')" text="Create An Account" />
      </div>
      <form v-show="isLoggedIn" class="form" :class="{ 'has-errors' : $v.$anyError }" @submit.prevent="handleSubmit">
        <div class="form__row">
          <div class="form__field form__field--medium" :class="{ 'has-error': $v.name.$error }">
            <label class="accessible" for="name">Name (Required)</label>
            <input id="name" name="name" type="text" placeholder="Name" v-model.trim="name" />
            <span class="error" v-if="$v.name.$error && !$v.name.required">This field is required</span>
          </div>
        </div>
        <div class="form__row">
          <div class="form__field form__field--medium" :class="{ 'has-error': $v.email.$error }">
            <label class="accessible" for="email">Email (Required)</label>
            <input id="email" name="email" type="text" placeholder="Email" v-model.trim="email" />
            <span class="error" v-if="$v.email.$error && !$v.email.required">This field is required</span>
            <span class="error" v-if="$v.email.$error && !$v.email.email">Please enter a valid email address</span>
          </div>
        </div>
        <div class="form__row">
          <div class="form__field" :class="{ 'has-error': $v.rating.$error }">
            <fieldset>
              <legend>Rating<span class="accessible">(Required)</span></legend>
              <div class="rating">
                <template v-for="n in [5, 4, 3, 2, 1]">
                  <input :id="'rating-' + n" type="radio" :value="n" name="rating" v-model="rating" />
                  <label :for="'rating-' + n"><SvgIcon name="star-empty" /><SvgIcon name="star-filled" /></label>
                </template>
              </div>
            </fieldset>
            <span class="error" v-if="$v.rating.$error && !$v.rating.required">This field is required</span>
          </div>
        </div>
        <div class="form__row">
          <div class="form__field form__field--medium" :class="{ 'has-error': $v.reviewTitle.$error }">
            <label class="accessible" for="title">Review Title (Required)</label>
            <input id="title" type="text" placeholder="Review Title" v-model.trim="reviewTitle" />
            <span class="error" v-if="$v.reviewTitle.$error && !$v.reviewTitle.required">This field is required</span>
          </div>
        </div>
        <div class="form__row">
          <div class="form__field" :class="{ 'has-error': $v.reviewBody.$error }">
            <label class="accessible" for="body">Review Body (Required)</label>
            <textarea id="body" placeholder="Write your review here" v-model.trim="reviewBody"></textarea>
            <span class="description">{{ $v.reviewBody.$model ? $v.reviewBody.$model.length : 0 }} / {{ $v.reviewBody.$params.maxLength.max }}</span>
            <span class="error" v-if="$v.reviewBody.$error && !$v.reviewBody.required">This field is required</span>
            <span class="error" v-if="$v.reviewBody.$error && !$v.reviewBody.maxLength">You can only enter up to {{ $v.reviewBody.$params.maxLength.max }} characters</span>
          </div>
        </div>
        <div class="form__row form__row--button">
          <div class="form__field">
            <Button type="submit" :text="buttonText" />
          </div>
        </div>
        <FormFeedback :showFeedback="showFeedback" :submitState="submitState" :submitMessage="submitMessage" />
      </form>
    </template>
  </Expand>
</template>

<script>
  import axios from 'axios';

  import { mapState, mapGetters, mapActions } from 'vuex';
  import { required, email, minValue, maxLength } from 'vuelidate/lib/validators';

  import formMixin from '@/mixins/formMixin';

  import Expand from '@/components/header/Expand';
  import SvgIcon from '@/components/global/SvgIcon';
  import Button from '@/components/global/Button';
  import FormFeedback from '@/components/global/FormFeedback';

  export default {
    name: 'LeaveAReview',

    mixins: [ formMixin ],

    components: {
      Expand,
      SvgIcon,
      Button,
      FormFeedback
    },

    data() {
      return {
        name: null,
        email: null,
        rating: 0,
        reviewTitle: null,
        reviewBody: null,
        defaultButtonText: 'Submit Review'
      }
    },

    computed: {
      ...mapState({
        locale: state => state.locale,
        product: state => state.header.review.product,
        productId: state => state.header.review.productId
      }),
      ...mapGetters([
        'isLoggedIn',
        'domain'
      ])
    },

    validations: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      rating: {
        required: minValue(1)
      },
      reviewTitle: {
        required
      },
      reviewBody: {
        required,
        maxLength: maxLength(500) // This is an arbitrary number I just came up with...could be anything
      }
    },

    methods: {
      ...mapActions([
        'openHeaderMenuExpand',
        'closeHeaderMenu'
      ]),
      async handleSubmit() {
        this.$v.$touch();
        this.showFeedback = false;
        this.isSubmitting = true;

        if (this.$v.$invalid) {
          this.handleFormFeedback('error');
        } else {
          const shopifyProduct = this.locale == 'en-ca' ? this.product.shopify_product_ca : this.product.shopify_product_us;

          const review = {
            appkey: this.$config[this.locale].yotpo.reviewsKey,
            domain: `${this.locale == 'en-ca' ? 'gee-beauty-toronto' : 'gee-beauty-bal-harbour'}.myshopify.com`,
            sku: this.productId,
            product_title: this.$prismic.asText(this.product.title),
            product_url: this.domain + this.$route.path,
            product_image_url: shopifyProduct.image && shopifyProduct.image.src,
            display_name: this.name,
            email: this.email,
            review_score: this.rating,
            review_title: this.reviewTitle,
            review_content: this.reviewBody
          }

          try {
            // To add a review in development, use https://api.yotpo.com/reviews/dynamic_create
            const response = await axios.post('https://api.yotpo.com/v1/widget/reviews', review);

            if(response.status == 200) {
              this.handleFormFeedback('success', 'Your review has been submitted!');

              setTimeout(() => {
                this.closeHeaderMenu();
                this.resetForm();
              }, 2500);
            } else {
              this.handleFormFeedback('error', 'Something went wrong on the server. Please refresh try again.');
            }
          } catch (err) {
            console.log(err);
            if (err.response.data.error_type === "Exceptions::InvalidParams") {
              this.handleFormFeedback('error', 'You must fill out the title and body for your review.');
            } else {
              this.handleFormFeedback('error', 'Something went wrong on the server. Please try again.');
            }
          }
        }
      },
      resetForm() {
        this.name = null;
        this.email = null;
        this.rating = 0;
        this.reviewTitle = null;
        this.reviewBody = null;

        this.$v.$reset();
      }
    }
  }
</script>
